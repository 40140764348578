import { useEffect, useRef, useState } from 'react';

const googleClientId = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID!;

type GsiCallback = (
  response: google.accounts.id.CredentialResponse,
) => Promise<void>;

interface GsiSetupReturn {
  gsiContainer: React.MutableRefObject<HTMLDivElement | null>;
  handleGsi: () => void;
}

export function useGsiSetup(gsiCallback: GsiCallback): GsiSetupReturn {
  const [gsiLoaded, setGsiLoaded] = useState(false);
  const [gsiInitalised, setGsiInitalised] = useState(false);

  const hiddenGSIContainer = useRef<HTMLDivElement | null>(null);
  const hiddenGSIButton = useRef<HTMLDivElement | null>(null);

  // Check for the GSI script to have loaded
  useEffect(() => {
    const interval = setInterval(() => {
      if (typeof google === 'undefined') return;

      setGsiLoaded(true);
      clearInterval(interval);
    }, 100);

    return () => clearInterval(interval);
  }, []);

  // Initialize the GSI library
  useEffect(() => {
    if (!gsiLoaded) return;
    if (!window.google) return;

    google.accounts.id.initialize({
      client_id: googleClientId,
      itp_support: true,
      ux_mode: 'popup',
      callback: (response) => {
        gsiCallback(response);
      },
    });

    setGsiInitalised(true);
  }, [gsiLoaded, gsiCallback]);

  // Render the hidden GSI button
  useEffect(() => {
    if (!gsiInitalised) return;
    if (!hiddenGSIContainer.current) return;
    if (!window.google) return;

    // Settings don't matter as it's hidden anyway
    window.google.accounts.id.renderButton(hiddenGSIContainer.current, {
      type: 'icon',
      width: '200',
    });

    const googleLoginWrapperButton: HTMLDivElement | null =
      hiddenGSIContainer.current.querySelector('div[role=button]');

    if (!googleLoginWrapperButton || googleLoginWrapperButton.tagName !== 'DIV')
      return;

    hiddenGSIButton.current = googleLoginWrapperButton;
  }, [gsiInitalised]);

  return {
    gsiContainer: hiddenGSIContainer,
    handleGsi: () => hiddenGSIButton.current?.click(),
  };
}
