import { useCallback, useState } from 'react';

import { csrClient } from '../../../apollo/api/avantArteClient';
import {
  GeneratedCreateSubscriptionsInput,
  GeneratedIsSubscribedQuery,
  GeneratedIsSubscribedQueryVariables,
  IsSubscribedDocument,
  useCreateSubscriptionsMutation,
} from '../../../types/generated';

type Subscriptions = GeneratedCreateSubscriptionsInput[];

export const useSubscriptionsManager = () => {
  const [create] = useCreateSubscriptionsMutation();
  const [loading, setLoading] = useState(false);

  const getSubscriptions = useCallback(async (subscriptions: Subscriptions) => {
    const { data } = await csrClient.query<
      GeneratedIsSubscribedQuery,
      GeneratedIsSubscribedQueryVariables
    >({
      fetchPolicy: 'network-only',
      query: IsSubscribedDocument,
      variables: {
        subscriptions: subscriptions.map((s) => ({
          objectId: s.objectId,
          type: s.type,
        })),
      },
    });
    return data.isSubscribed.subscriptions;
  }, []);

  const isSubscribedTo = useCallback(
    async (subscriptions: Subscriptions) => {
      const data = await getSubscriptions(subscriptions);
      return subscriptions.every((s) =>
        data.find((d) => d.objectId === s.objectId && d.sType === s.type),
      );
    },
    [getSubscriptions],
  );

  const subscribe = async (subscriptions: Subscriptions) => {
    setLoading(true);
    const isSubscribed = await isSubscribedTo(subscriptions);
    if (isSubscribed) {
      setLoading(false);
      return false;
    }

    const { data } = await create({ variables: { subscriptions } });
    setLoading(false);

    return Boolean(data?.createSubscriptions.subscribed);
  };

  return { loading, isSubscribedTo, getSubscriptions, subscribe };
};
