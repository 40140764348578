import {
  GeneratedCreateSubscriptionsInput,
  GeneratedSubscriptionType,
} from '../types/generated';

export const GENERAL_SUBSCRIPTION = 'General';

export const generalSubscriptionsInput: GeneratedCreateSubscriptionsInput = {
  type: GeneratedSubscriptionType.General,
  objectId: GENERAL_SUBSCRIPTION,
  name: GENERAL_SUBSCRIPTION,
  ref: '',
};
