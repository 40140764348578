export enum AuthAnalytics {
  SignUp = 'create account',
  SignUpCode = 'create account code',
  SignUpGoogle = 'create account google',
  SignUpPhone = 'create account add phone number',
  SignUpProfile = 'create account profile',
  SignUpConfirmation = 'create account confirmation',
  SignIn = 'sign in',
  SignInGoogle = 'sign in google',
  SignInCode = 'sign in code',
  SignInPhone = 'sign in add phone number',
  SignInProfile = 'sign in profile',
}
