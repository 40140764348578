import {
  GeneratedQuerySubscriptionsCountArgs,
  useGetSubscriptionsCountQuery,
} from '../../types/generated';

export const useSubscriptionsCount = ({
  objectId,
  type,
  withRandomAccounts,
}: GeneratedQuerySubscriptionsCountArgs) => {
  const response = useGetSubscriptionsCountQuery({
    variables: {
      objectId,
      type,
      withRandomAccounts,
    },
  });

  return response.data?.subscriptionsCount;
};
