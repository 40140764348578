import { useTranslation } from 'next-i18next';
import React from 'react';

import { useFlow } from '../../../providers';
import { AuthFlow } from '../types';

export const AuthTitleContainer: React.FC = () => {
  const { flowConfig } = useFlow();
  const { t } = useTranslation('authentication');

  if (!flowConfig) return null;
  const { name } = flowConfig;

  const isSignUp = (name as AuthFlow) === AuthFlow.SignUp;
  const isSignIn = (name as AuthFlow) === AuthFlow.SignIn;

  if (isSignIn) {
    return t('screens.signIn.title');
  }

  if (isSignUp) {
    return t('screens.signUp.title');
  }

  return null;
};

export const ProfileTitleContainer: React.FC = () => {
  const { t } = useTranslation('authentication');

  return t('screens.profile.title');
};
