export const normalize = (value: string): string => {
  if (value.length > 2) {
    throw Error('Invalid date');
  }

  if (value.length === 2) {
    return value;
  }

  return `0${value}`;
};

export const normalizeDateOfBirth = (
  day: string | undefined,
  month: string | undefined,
  year: string | undefined,
) => {
  if (!day || !month || !year) return undefined;

  return `${year}-${normalize(month)}-${normalize(day)}`;
};

export const splitDateOfBirth = (dateOfBirth: string | undefined | null) => {
  if (!dateOfBirth)
    return { day: undefined, month: undefined, year: undefined };

  const [year, month, day] = dateOfBirth.split('-');

  return {
    day,
    month,
    year,
  };
};
