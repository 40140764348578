import React from 'react';

import { GENERAL_SUBSCRIPTION } from '../../../constants/subscriptions';
import { useIsSubscribed } from '../../../hooks/subscription';
import {
  GeneratedCreateSubscriptionsInput,
  GeneratedIsSubscribedSubscriptionsInput,
  GeneratedSubscriptionType,
  useCreateSubscriptionsMutation,
} from '../../../types/generated';
import { useAnalyticsEmitter } from '../../../utils/analytics/emitter';
import { extractApolloError } from '../../../utils/errors';
import { ProfileFormContainer } from './ProfileFormContainer';

const generalSubscription: GeneratedIsSubscribedSubscriptionsInput = {
  type: GeneratedSubscriptionType.General,
  objectId: GENERAL_SUBSCRIPTION,
};

const generalSubscriptionCreate: GeneratedCreateSubscriptionsInput = {
  ...generalSubscription,
  name: GENERAL_SUBSCRIPTION,
  ref: '',
};

interface Props {
  optInGeneral: boolean;
}

export const ProfileContainer: React.FC<Props> = ({ optInGeneral }) => {
  const { checkIsSubscribed, loading } = useIsSubscribed([generalSubscription]);
  const [emitter] = useAnalyticsEmitter();
  const [subscribe] = useCreateSubscriptionsMutation();

  const isSubscribedGeneral = checkIsSubscribed(
    generalSubscription.objectId,
    generalSubscription.type,
  );

  const subscribeToGeneral = async () => {
    try {
      const { errors } = await subscribe({
        variables: { subscriptions: generalSubscriptionCreate },
      });

      const error = extractApolloError(errors);

      if (error) {
        emitter('', 'error', { error });
      }
    } catch (err) {
      emitter('', 'error', { error: err });
    }
  };

  return (
    <ProfileFormContainer
      isSubscribedGeneral={isSubscribedGeneral}
      loading={loading}
      onSubscribe={subscribeToGeneral}
      optInGeneral={optInGeneral}
    />
  );
};
