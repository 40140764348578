import { TurnstileInstance } from '@marsidev/react-turnstile';
import dynamic from 'next/dynamic';
import { useCallback, useMemo, useRef } from 'react';

export const LazyCloudflareTurnstile = dynamic(
  () => import('./CloudflareTurnstile'),
  { ssr: false },
);

export const useResetTurnstile = () => {
  const ref = useRef<TurnstileInstance>(null);
  const reset = useCallback(() => ref.current?.reset(), []);
  return useMemo(() => ({ turnstileRef: ref, turnstileReset: reset }), [reset]);
};
