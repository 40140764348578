import { ApolloError } from '@apollo/client';
import { Alert, Button, InputField } from '@design-system';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  LazyCloudflareTurnstile,
  useResetTurnstile,
} from '../../../components/Captcha/LazyCloudflareTurnstile';
import { useAuth, useFlow } from '../../../providers';
import { AuthState } from '../../../types/Auth';
import { TrackEmailSubscription } from '../../../utils/analytics';
import { useAnalyticsEmitter } from '../../../utils/analytics/emitter';
import {
  extractApolloError,
  isLoginCodeExpiredError,
  isLoginCodeInvalidError,
  isRateLimitError,
} from '../../../utils/errors';
import { ApiError } from '../components';
import { AuthAnalytics, AuthFlow } from '../types';
import { PhoneContainer } from './PhoneContainer';
import { ProfileContainer } from './ProfileContainer';
import { SuccessContainer } from './SuccessContainer';
import { ProfileTitleContainer } from './TitleContainers';

function extractErrorMsg(error: ApolloError): string {
  if (isLoginCodeExpiredError(error)) {
    return 'Code expired';
  }

  if (isRateLimitError(error)) {
    return 'Rate limit reached';
  }

  if (isLoginCodeInvalidError(error)) {
    return 'Invalid code';
  }

  return 'Unknown error';
}

interface FormValues {
  code: string;
}

interface Props {
  hideSuccessScreen?: boolean;
  optInGeneral: boolean;
  showPhoneStep?: boolean;
  skipProfileStep?: boolean;
  stepId: string;
  hardRefresh: boolean;
}

export const VerifyContainer: React.FC<Props> = ({
  hardRefresh,
  hideSuccessScreen = true,
  optInGeneral,
  showPhoneStep = false,
  skipProfileStep = false,
  stepId,
}) => {
  const { t } = useTranslation('authentication');
  const [emitter] = useAnalyticsEmitter();
  const { currentStepId, flowConfig, goNextStep, startFlow } = useFlow();
  const { authState, emailForCode, login, signupAndSubscribe, validateCode } =
    useAuth();

  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string>();
  const { turnstileRef, turnstileReset } = useResetTurnstile();
  const { reload } = useRouter();

  const [apiError, setApiError] = useState<ApolloError | null>(null);
  const {
    formState,
    handleSubmit: formHandleSubmit,
    register,
  } = useForm<FormValues>({ defaultValues: { code: '' } });
  const isCurrentStep = currentStepId === stepId;

  const validationError = formState.errors.code?.message;

  useEffect(() => {
    if (validationError) void emitter('', 'error', { error: validationError });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationError]);

  const handleValidateCode = useCallback(
    async (code: string) => {
      if (!emailForCode) return;

      try {
        const { data, errors } = await validateCode(code, emailForCode);

        const error = extractApolloError(errors);

        if (error) {
          emitter('', 'error', { error });
          setApiError(error);
          return;
        }

        const { account, isNewAccount } = data?.useLoginCode || {};

        if (account?.id) {
          setApiError(null);
          emitter('', 'success', { account_already_exists: !isNewAccount });

          TrackEmailSubscription({
            artistId: flowConfig?.analyticsProperties?.artist_id,
            artistName: flowConfig?.analyticsProperties?.['artist name'],
            emailAddress: account.email || '',
            releaseName: flowConfig?.analyticsProperties?.subscription_name,
            productId: flowConfig?.analyticsProperties?.product_id,
            productTitle: flowConfig?.analyticsProperties?.product_title,
            mode: 'GENERAL',
            trackEvent: false,
            type: 'success',
          });

          const name = flowConfig?.name as AuthFlow;
          const signIn = name === AuthFlow.SignIn;

          const isPhoneComplete = Boolean(
            !showPhoneStep || account.phone !== null,
          );

          const isProfileComplete = Boolean(
            account.fullName && account.pronouns && account.countryCode,
          );

          if ((isPhoneComplete && isProfileComplete) || skipProfileStep) {
            goNextStep();
            return;
          }

          startFlow({
            name: AuthFlow.ProfileOnly,
            steps: [
              {
                id: 'profile',
                content: <ProfileContainer optInGeneral={optInGeneral} />,
                title: <ProfileTitleContainer />,
                analytics: {
                  name: signIn
                    ? AuthAnalytics.SignInProfile
                    : AuthAnalytics.SignUpProfile,
                },
              },
              ...(isPhoneComplete === false
                ? [
                    {
                      id: 'phone',
                      content: (
                        <PhoneContainer phone={account.phone || undefined} />
                      ),
                      title: t('screens.phone.title'),
                      analytics: {
                        name: signIn
                          ? AuthAnalytics.SignInPhone
                          : AuthAnalytics.SignUpPhone,
                      },
                    },
                  ]
                : []),
              ...(!signIn && !hideSuccessScreen
                ? [
                    {
                      id: 'success',
                      content: <SuccessContainer />,
                      hideBackButton: true,
                      analytics: {
                        name: AuthAnalytics.SignUpConfirmation,
                      },
                    },
                  ]
                : []),
            ],
            onFlowComplete: flowConfig?.onFlowComplete,
          });
        }
      } catch (error) {
        turnstileReset();
        emitter('', 'error', { error: extractErrorMsg(error as ApolloError) });

        setApiError(error as ApolloError);
      }
    },
    [
      emailForCode,
      validateCode,
      emitter,
      flowConfig?.analyticsProperties,
      flowConfig?.name,
      flowConfig?.onFlowComplete,
      showPhoneStep,
      skipProfileStep,
      startFlow,
      optInGeneral,
      t,
      hideSuccessScreen,
      goNextStep,
      turnstileReset,
    ],
  );

  const isSignUp = (flowConfig?.name as AuthFlow) === AuthFlow.SignUp;

  const requestNewCode = useCallback(async () => {
    // NOTE(afr): Add properties about how many times the user has requested a new code?
    emitter('resend', 'clicked');
    if (!emailForCode) return;

    setShowCaptcha(true);
    try {
      const response = isSignUp
        ? await signupAndSubscribe({ email: emailForCode, captchaToken })
        : await login(emailForCode, captchaToken);

      const error = extractApolloError(response?.errors);

      if (error) {
        emitter('', 'error', { error });
      }

      setApiError(error);
    } catch (e) {
      emitter('', 'error', { error: extractErrorMsg(e as ApolloError) });
      setApiError(e as ApolloError);
    } finally {
      setShowCaptcha(false);
    }
  }, [
    captchaToken,
    emailForCode,
    emitter,
    isSignUp,
    login,
    signupAndSubscribe,
  ]);

  const handleSubmit = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      formHandleSubmit(async (values: FormValues) => {
        ev.preventDefault();
        emitter('submit', 'clicked');

        await handleValidateCode(values.code);

        if (hardRefresh) {
          reload();
        }
      })(ev);
    },
    [emitter, formHandleSubmit, handleValidateCode],
  );

  return (
    <form className="flex w-full flex-col gap-4" onSubmit={handleSubmit}>
      {apiError ? (
        <ApiError apiError={apiError} retry={requestNewCode} />
      ) : (
        <Alert status="success">
          {t('screens.verifyCode.explainerBanner')}
        </Alert>
      )}

      <InputField
        disabled
        id="email"
        label={t('screens.verifyCode.emailInputLabel')}
        name="email"
        value={emailForCode}
      />

      <div className="relative">
        <InputField
          {...register('code', {
            required: {
              value: true,
              message: t('screens.verifyCode.validation.required'),
            },
          })}
          error={validationError}
          id="code"
          label={t('screens.verifyCode.label')}
          name="code"
        />
        <button
          className={classNames('hyperlink absolute right-4', {
            'top-[40%]': validationError,
            'top-1/2': !validationError,
          })}
          onClick={() => void requestNewCode()}
          type="button"
        >
          {t('screens.verifyCode.resendBtn')}
        </button>
      </div>

      {isCurrentStep ? (
        <LazyCloudflareTurnstile
          actionName={isSignUp ? 'sign_up_email' : 'sign_in_email'}
          className={classNames({ 'hidden': !showCaptcha })}
          onError={() => setCaptchaToken(void 0)}
          onExpire={() => setCaptchaToken(void 0)}
          onLoadStart={() => setCaptchaToken(void 0)}
          onSuccess={setCaptchaToken}
          turnstileRef={turnstileRef}
        />
      ) : null}

      <Button
        fullWidth
        label={t('screens.verifyCode.continueBtn')}
        loading={Boolean(authState === AuthState.LOADING)}
        size="lg"
        type="submit"
        variant="primary"
      />
    </form>
  );
};
