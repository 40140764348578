import { useAuth } from '../../providers';
import {
  GeneratedIsSubscribedSubscriptionsInput,
  GeneratedSubscriptionType,
  useIsSubscribedQuery,
} from '../../types/generated';

export const useIsSubscribed = (
  subscriptions: GeneratedIsSubscribedSubscriptionsInput[],
) => {
  const { isLoggedIn } = useAuth();

  const subscriptionsWithId = subscriptions
    .filter((s) => s.objectId !== undefined && s.objectId !== '')
    .map((subs) => ({ objectId: subs.objectId, type: subs.type }));

  const response = useIsSubscribedQuery({
    variables: { subscriptions: subscriptionsWithId },
    fetchPolicy: 'cache-and-network',
    skip: !isLoggedIn || subscriptionsWithId.length === 0,
  });

  if (response.error) {
    return {
      ...response,
      checkIsSubscribed: () => false,
      isSubscribedAll: false,
    };
  }

  const checkIsSubscribed = (
    objectId: string | undefined,
    type: GeneratedSubscriptionType,
  ): boolean => {
    if (objectId === undefined) return false;

    if (!response.data) return false;

    return Boolean(
      response.data?.isSubscribed?.subscriptions.find(
        (s) => s.objectId === objectId && s.sType === type,
      ),
    );
  };

  const isSubscribedAll = subscriptions.every((s) => {
    return checkIsSubscribed(s.objectId, s.type);
  });

  return { ...response, checkIsSubscribed, isSubscribedAll };
};
