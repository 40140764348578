import { ApolloError } from '@apollo/client';
import { Alert, Button, InputField } from '@design-system';
import { useTranslation } from 'next-i18next';
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  LazyCloudflareTurnstile,
  useResetTurnstile,
} from '../../../components/Captcha/LazyCloudflareTurnstile';
import { useAuth, useFlow } from '../../../providers';
import { AuthState } from '../../../types/Auth';
import { GeneratedCreateSubscriptionsInput } from '../../../types/generated';
import { useAnalyticsEmitter } from '../../../utils/analytics/emitter';
import { extractApolloError } from '../../../utils/errors';
import { AuthFlow } from '../types';

interface Props {
  stepId: string;
  subscriptions?: GeneratedCreateSubscriptionsInput[];
}

interface FormValues {
  email: string;
}

export const EmailContainer: React.FC<Props> = ({
  stepId,
  subscriptions = [],
}) => {
  const [captchaToken, setCaptchaToken] = useState<string>();
  const { turnstileRef, turnstileReset } = useResetTurnstile();
  const { currentStepId, flowConfig, goNextStep } = useFlow();
  const { t } = useTranslation('authentication');
  const [emitter] = useAnalyticsEmitter();
  const { authState, signupAndSubscribe } = useAuth();

  const [apiError, setApiError] = useState<ApolloError | null>(null);

  const form = useForm<FormValues>({
    defaultValues: {
      email: '',
    },
  });
  const emailValidation = form.formState.errors.email?.message;

  const isSignUp = (flowConfig?.name as AuthFlow) === AuthFlow.SignUp;
  const isCurrentStep = currentStepId === stepId;

  useEffect(() => {
    if (emailValidation) emitter('', 'error', { error: emailValidation });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailValidation]);

  const handleAuthentication = useCallback(
    async (email: string) => {
      const { data, errors } = await signupAndSubscribe({
        email,
        captchaToken,
        subscriptions,
      });

      const error = extractApolloError(errors);
      if (error) {
        setApiError(error);
        turnstileReset();
        emitter('', 'error', { error });
        return;
      }

      if (data?.signupAndSubscribe === true) {
        emitter('', 'success');

        if (isSignUp && subscriptions.length > 0) {
          emitter(
            'subscribe',
            'success',
            { is_signed_in: false },
            { omitObject: true },
          );
        }
        goNextStep();
      }
    },
    [
      signupAndSubscribe,
      captchaToken,
      subscriptions,
      turnstileReset,
      emitter,
      isSignUp,
      goNextStep,
    ],
  );

  const handleSubmit = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      form.handleSubmit(async (values: FormValues) => {
        ev.preventDefault();
        emitter('', 'submit');
        setApiError(null);

        await handleAuthentication(values.email.trim());
        return;
      })(ev);
    },
    [form, emitter, handleAuthentication],
  );

  return (
    <form className="flex w-full flex-col gap-4" onSubmit={handleSubmit}>
      {apiError && <Alert status="error">{t('errors.generic')}</Alert>}
      <InputField
        {...form.register('email', {
          required: {
            value: true,
            message: t('screens.email.input.validation.required'),
          },
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: t('screens.email.input.validation.invalid'),
          },
        })}
        error={emailValidation}
        id="email"
        label={t('screens.email.input.label')}
        name="email"
      />

      <div className="flex w-full flex-col">
        {isCurrentStep && (
          <LazyCloudflareTurnstile
            actionName={isSignUp ? 'sign_up_email' : 'sign_in_email'}
            onError={() => setCaptchaToken(void 0)}
            onExpire={() => setCaptchaToken(void 0)}
            onLoadStart={() => setCaptchaToken(void 0)}
            onSuccess={setCaptchaToken}
            turnstileRef={turnstileRef}
          />
        )}

        <Button
          disabled={captchaToken === void 0}
          fullWidth
          label={t(
            isSignUp ? 'screens.email.signUpBtn' : 'screens.email.signInBtn',
          )}
          loading={Boolean(authState === AuthState.LOADING)}
          size="lg"
          type="submit"
          variant="primary"
        />
      </div>
    </form>
  );
};
