import { differenceInDays, getDaysInMonth, isFuture, setDate } from 'date-fns';
import { FieldError } from 'react-hook-form';

import { CountryCode } from '../../../types/Account';
import { GeneratedPronouns } from '../../../types/generated';

export interface ProfileFormValues {
  fullName: string;
  pronouns: GeneratedPronouns;
  day?: string;
  month?: string;
  year?: string;
  countryCode: CountryCode;
  subscribeToGeneral: boolean;
}

export type ProfileFormErrors = Record<keyof ProfileFormValues, FieldError>;

interface ProfileFormResolverResult {
  values: ProfileFormValues;
  errors: object | ProfileFormErrors;
}

export const profileFormResolver = (
  values: ProfileFormValues,
): ProfileFormResolverResult => {
  const errors: Record<keyof ProfileFormValues, FieldError> = {
    fullName: { message: undefined, type: '' },
    day: { message: undefined, type: '' },
    month: { message: undefined, type: '' },
    year: { message: undefined, type: '' },
    countryCode: { message: undefined, type: '' },
    pronouns: { message: undefined, type: '' },
    subscribeToGeneral: { message: undefined, type: '' },
  };

  // Full name validation
  if (!values.fullName) {
    errors.fullName.message =
      'screens.profile.fields.fullName.validation.required';
  } else if (!/[\p{L}\p{M}]+(\s[\p{L}\p{M}]+)+/u.test(values.fullName)) {
    errors.fullName.message =
      'screens.profile.fields.fullName.validation.incomplete';
  }

  const { day, month, year } = values;

  // Date of birth only required if at least one of the fields is filled
  if (!day && !month && !year)
    return { values, errors: errors.fullName.message ? errors : {} };

  // If one field is filled, all need to be filled
  if (!day || !month || !year) {
    if (!day) {
      errors.day.message =
        'screens.profile.fields.birthday.validation.incomplete';
    }
    if (!month) {
      errors.month.message =
        'screens.profile.fields.birthday.validation.incomplete';
    }
    if (!year) {
      errors.year.message =
        'screens.profile.fields.birthday.validation.incomplete';
    }

    return { values, errors };
  }

  const numericalMonth = Number(month);
  const numericalDay = Number(day);
  const birthMonth = new Date(Number(year), numericalMonth - 1);
  const birthday = setDate(birthMonth, numericalDay);

  // Date must be in the past
  if (isFuture(birthday)) {
    errors.day.message = 'screens.profile.fields.birthday.validation.future';
    errors.month.message = 'screens.profile.fields.birthday.validation.future';
    errors.year.message = 'screens.profile.fields.birthday.validation.future';

    return { values, errors };
  }

  // Date must be a valid date
  if (year?.length !== 4 || getDaysInMonth(birthMonth) < numericalDay) {
    errors.day.message = 'screens.profile.fields.birthday.validation.invalid';
    errors.month.message = 'screens.profile.fields.birthday.validation.invalid';
    errors.year.message = 'screens.profile.fields.birthday.validation.invalid';
    return { values, errors };
  }

  // User must be at least 13 years old to use Avant Arte
  if (differenceInDays(new Date(), birthday) <= 13 * 365) {
    errors.day.message = 'screens.profile.fields.birthday.validation.tooYoung';
    errors.month.message =
      'screens.profile.fields.birthday.validation.tooYoung';
    errors.year.message = 'screens.profile.fields.birthday.validation.tooYoung';
    return { values, errors };
  }

  errors.day.message = undefined;
  errors.month.message = undefined;
  errors.year.message = undefined;

  return {
    values,
    errors: errors.fullName.message ? errors : {},
  };
};
