import { FC, useCallback } from 'react';

import { useFlow } from '../../../providers';
import { useUpdateAccountMutation } from '../../../types/generated';
import { useAnalyticsEmitter } from '../../../utils/analytics/emitter';
import { PhoneFormContainer } from './PhoneFormContainer';

interface Props {
  phone?: string;
}

export const PhoneContainer: FC<Props> = ({ phone = '' }) => {
  const { currentStepId, goNextStep } = useFlow();
  const [updateAccount, { error, loading }] = useUpdateAccountMutation();
  const [emitter] = useAnalyticsEmitter();

  const handleSkip = useCallback(async () => {
    await updateAccount({ variables: { phone: '' } });
    emitter('', 'skip clicked');
    goNextStep();
  }, [emitter, goNextStep, updateAccount]);

  const handleSubmit = useCallback(
    async (data: { phone: string }) => {
      const lib = await import('libphonenumber-js');
      const value = lib.parsePhoneNumber(data.phone);
      const res = await updateAccount({
        variables: {
          phone: `${value.countryCallingCode}-${value.nationalNumber}`,
        },
      });
      if (!res.errors) {
        emitter('', 'save clicked');
        goNextStep();
      }
    },
    [emitter, goNextStep, updateAccount],
  );

  if (currentStepId !== 'phone') {
    return null;
  }

  return (
    <PhoneFormContainer
      error={Boolean(error)}
      loading={loading}
      onSkip={handleSkip}
      onSubmit={handleSubmit}
      phone={phone}
    />
  );
};
