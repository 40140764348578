/* eslint-disable @next/next/no-img-element */
import { Button } from '@design-system';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';

import { pathFor } from '../../../helpers/links';
import { useAuth, useFlow } from '../../../providers';
import { useAnalyticsEmitter } from '../../../utils/analytics/emitter';

export const SuccessContainer: FC = () => {
  const [emitter] = useAnalyticsEmitter();
  const { t } = useTranslation('authentication');
  const { user } = useAuth();
  const router = useRouter();
  const { closeFlow } = useFlow();

  const firstName = user?.fullName?.split(' ')[0];

  const nameText = firstName ? `${firstName},` : '';

  return (
    <div className="text-center">
      <p className="text-md font-bold">
        {t('screens.success.generic.titleIntro')} {nameText}
      </p>
      <p className="mb-3 text-md font-bold">
        {t('screens.success.generic.titleOutro')}
      </p>
      <div className="text-neutral-4">
        {t('screens.success.generic.subtitle')}
      </div>
      <div className="my-6 flex flex-row items-center justify-center">
        <img
          alt="Takahiro Kamuto"
          className="rounded-sm"
          src="/images/takahiro-kamuto.webp"
          width={76}
        />
        <img
          alt="Moe Nakamura"
          className="mx-3 rounded-sm"
          src="/images/moe-nakamura.webp"
          width={96}
        />
        <img
          alt="Dennis Osadebe"
          className="rounded-sm"
          src="/images/dennis-osadebe.webp"
          width={76}
        />
      </div>
      <Button
        fullWidth
        label={t('screens.success.generic.button')}
        onClick={() => {
          emitter('discover', 'clicked');
          router.push(pathFor({ page: 'ARTISTS_INDEX_ROOT_PATH' }));
          closeFlow();
        }}
        size="lg"
        variant="primary"
      />
    </div>
  );
};
