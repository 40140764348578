import React, { FC } from 'react';

interface Props {
  text: string;
  ctaText: string;
  onClick: () => void;
}

export const AuthFooter: FC<Props> = ({ ctaText, onClick, text }) => (
  <div className="bg-neutral-1 py-4 text-center md:rounded-b-lg">
    {text}&nbsp;
    <button className="hyperlink" onClick={onClick} type="button">
      {ctaText}
    </button>
  </div>
);
