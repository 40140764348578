import { Trans, useTranslation } from 'next-i18next';
import qs from 'query-string';
import React from 'react';

import { Image } from '../../../components/Image';
import UnderlinedLink from '../../../components/UnderlinedLink';
import { pathFor } from '../../../helpers/links';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { GeneratedCreateSubscriptionsInput } from '../../../types/generated';
import { isSignInFrameUpgrade } from '../utils/pageMatcher';
import { EmailContainer } from './EmailContainer';
import { GoogleAuthContainer } from './GoogleAuthContainer';

interface Props {
  stepId: string;
  imageUrl?: string;
  hideSuccessScreen?: boolean;
  optInGeneral: boolean;
  showPhoneStep?: boolean;
  subscriptions: GeneratedCreateSubscriptionsInput[];
  hardRefresh: boolean;
}

function inAppBrowser() {
  if (typeof navigator === 'undefined') return false;

  const ua = (navigator.userAgent || navigator.vendor || '').toLowerCase();

  return (
    ua.includes('instagram') ||
    ua.includes('facebook') ||
    ua.includes('fban') ||
    ua.includes('fbav') ||
    ua.includes('twitter') ||
    ua.includes('wechat') ||
    ua.includes('pinterest') ||
    ua.includes('tiktok') ||
    ua.includes('reddit') ||
    ua.includes('discord')
  );
}

export const AuthMethodContainer: React.FC<Props> = ({
  hardRefresh,
  hideSuccessScreen = true,
  imageUrl,
  optInGeneral,
  showPhoneStep = false,
  stepId,
  subscriptions,
}) => {
  const queryParams = useQueryParams();
  const { t } = useTranslation('authentication');

  const frameUpgradeEmail =
    isSignInFrameUpgrade(queryParams.redirect) &&
    (qs.parse(qs.extract(queryParams.redirect)).email as string);

  const isAlternativeAuthAvailable = !inAppBrowser();

  return (
    <div className="flex flex-col gap-6">
      {frameUpgradeEmail ? (
        <p className="text-center">
          To check our bespoke framing options for your print, sign in with{' '}
          <strong>{frameUpgradeEmail}</strong>
        </p>
      ) : null}
      {imageUrl && (
        <div className="mx-auto h-24 w-24 md:h-32 md:w-32">
          <Image
            alt="Sign up image"
            className="overflow-hidden rounded-sm"
            src={imageUrl}
          />
        </div>
      )}
      <div className="relative">
        <EmailContainer stepId={stepId} subscriptions={subscriptions} />
      </div>

      {isAlternativeAuthAvailable && (
        <>
          <span className="mx-auto text-sm text-neutral-4">or</span>
          <GoogleAuthContainer
            hardRefresh={hardRefresh}
            hideSuccessScreen={hideSuccessScreen}
            optInGeneral={optInGeneral}
            showPhoneStep={showPhoneStep}
            subscriptions={subscriptions}
          />
        </>
      )}

      <p className="px-5 text-center text-neutral-4">
        {optInGeneral ? (
          <Trans i18nKey="screens.signUp.captionOptInGeneral" t={t}>
            By continuing, you’re agreeing to our&nbsp;
            <UnderlinedLink
              href={pathFor({ page: 'TERMS_AND_CONDITIONS' })}
              target="_blank"
            >
              terms &amp; conditions
            </UnderlinedLink>
            {' and '}
            <UnderlinedLink
              href={pathFor({ page: 'PRIVACY_POLICY' })}
              target="_blank"
            >
              privacy policy
            </UnderlinedLink>
            &nbsp;and opting in to receive updates and marketing emails.
          </Trans>
        ) : (
          <Trans i18nKey="screens.signUp.captionNoOptIn" t={t}>
            By continuing, you’re agreeing to our&nbsp;
            <UnderlinedLink
              href={pathFor({ page: 'TERMS_AND_CONDITIONS' })}
              target="_blank"
            >
              terms &amp; conditions
            </UnderlinedLink>
            {' and '}
            <UnderlinedLink
              href={pathFor({ page: 'PRIVACY_POLICY' })}
              target="_blank"
            >
              privacy policy
            </UnderlinedLink>
          </Trans>
        )}
      </p>
    </div>
  );
};
