import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';

import { Step, useFlow } from '../../../providers';
import { useAnalyticsEmitter } from '../../../utils/analytics/emitter';
import { AuthFooter } from '../components';
import { AuthFlow } from '../types';

interface Props {
  buildCtaFlowSteps: () => Step[];
}

export const AuthFooterContainer: FC<Props> = ({ buildCtaFlowSteps }) => {
  const { t } = useTranslation('authentication');
  const { flowConfig, startFlow } = useFlow();
  const [emitter] = useAnalyticsEmitter();
  const isSignUp = (flowConfig?.name as AuthFlow) === AuthFlow.SignUp;
  const isSignIn = (flowConfig?.name as AuthFlow) === AuthFlow.SignIn;

  const toggleFlow = () => {
    if (isSignUp) {
      emitter('sign in', 'clicked');
      startFlow({
        ...flowConfig,
        name: AuthFlow.SignIn,
        steps: buildCtaFlowSteps(),
      });
    } else if (isSignIn) {
      emitter('create account', 'clicked');
      startFlow({
        ...flowConfig,
        name: AuthFlow.SignUp,
        steps: buildCtaFlowSteps(),
      });
    }

    return;
  };

  return (
    <AuthFooter
      ctaText={
        isSignUp ? t('screens.signUp.footerCta') : t('screens.signIn.footerCta')
      }
      onClick={toggleFlow}
      text={
        isSignUp
          ? t('screens.signUp.footerIntro')
          : t('screens.signIn.footerIntro')
      }
    />
  );
};
